<template>
  <div class="store-time-management">
    <!-- 搜索条件 -->
    <div class="search-area">
      <div class="search-item">
        <span class="label">门店</span>
        <el-select-v2
          v-model="searchForm.storeCode"
          :options="storeList"
          placeholder="请选择门店"
          class="search-select userSelect"
          filterable
          @change="handleStoreChange"
        />
      </div>
      <div class="search-item">
        <el-button @click="handlePackageType" :disabled="searchForm.storeCode == ''">包厢类型</el-button>
        <el-button @click="handlePackageList" :disabled="searchForm.storeCode == ''">包厢列表</el-button>
      </div>
      <div class="search-item right">
        <el-button type="primary" @click="goToStoreFront">>>前往开店宝</el-button>
      </div>
    </div>

    <!-- 日期选择区域 -->
    <div class="date-section">
      <p style="color: #606266; font-size: 12px; margin-bottom: 10px">
        时段类型"
        可叠加：白天档：08:00~18：00；黄金档：18:00~23:30；午夜档：23:30~次日08:00。以下日期下蓝点表示已添加时段，灰点表示没有添加时段。各类型包厢库存根据POS收银机上设置包厢是否自动接单动态计算
      </p>
      <!-- 时间选择区域 -->
      <div class="time-selection">
        <!-- 周期选择 -->
        <div class="selection-group">
          <div class="selection-content">
            <el-radio-group v-model="selectedWeekday">
              <div class="date-item" v-for="(day, index) in weekDays" :key="index">
                <el-radio-button :label="day.id" :disabled="searchForm.storeCode == ''">
                  {{ day.label }}
                </el-radio-button>
                <div class="status-dot" :class="{ 'has-time': day.isHaveTimeSlot == 1 }"></div>
              </div>
              <div class="date-item" v-for="(date, index) in specialDates" :key="date.id">
                <el-radio-button :disabled="searchForm.storeCode == ''" :label="date.id">
                  {{ date.label }}
                </el-radio-button>
                <div class="status-dot" :class="{ 'has-time': date.isHaveTimeSlot == 1 }"></div>
                <el-icon
                  :size="12"
                  class="el-icon-delete"
                  @click="confirmDelete(date, index)"
                  style="cursor: pointer"
                />
              </div>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="special-date-actions">
        <el-button type="primary" @click="addTimeSlot" :disabled="searchForm.storeCode == ''">添加时段</el-button>

        <el-button type="primary" @click="showAddSpecialDate" :disabled="searchForm.storeCode == ''"
          >添加特殊日期</el-button
        >
        <el-button @click="copyOtherDate" :disabled="searchForm.storeCode == ''">复制到其他日期</el-button>
      </div>
    </div>
    <!-- 时段管理区域 -->
    <div class="time-management-area">
      <!-- 时段表格 -->
      <div class="time-table">
        <el-table :data="timeTableData" border>
          <el-table-column align="center" prop="timeSlot" label="时段" />
          <el-table-column align="center" prop="timeSlotType" label="时段类型" />
          <el-table-column align="center" prop="channel" label="开放渠道">
            <template #default="scope">
              <div class="channel-list">
                <div v-for="channel in scope.row.channel" :key="channel" class="channel-item">
                  {{ channels[channel] }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100" fixed="right">
            <template #default="scope">
              <el-button type="text" @click="deleteTimeSlot(scope.row)" style="color: red">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <PackageTypeDialog v-model="packageTypeVisible" :storeCode="searchForm.storeCode" />
    <PackageListDialog v-model="packageListVisible" :storeCode="searchForm.storeCode" />
    <AddTimeSlotDialog v-model="timeSlotDialogVisible" @success="handleTimeSlotSuccess" />

    <!-- 添加特殊日期对话框 -->
    <el-dialog v-model="specialDateDialogVisible" title="添加特殊日期" width="500px" :close-on-click-modal="false">
      <div class="special-date-form">
        <el-date-picker
          v-model="specialDateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :disabled-date="disabledDate"
          value-format="YYYY-MM-DD"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="specialDateDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleAddSpecialDate">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加复制日期对话框 -->
    <el-dialog v-model="copyDateDialogVisible" title="复制到其他日期" width="800px" :close-on-click-modal="false">
      <div class="copy-date-form">
        <div class="warning-tip">
          请注意！复制后该日期原有时段设置将被覆盖，价格表将被清空，请及时检查和填认。
        </div>
        <div class="date-tip">当前设置日期：{{ currentSelectedDate }}</div>
        <div class="copy-to">复制到的日期：</div>
        <div class="special-date-list">
          <el-checkbox-group v-model="copyForm.specialDates">
            <el-checkbox
              v-for="(dateRange, index) in filteredSpecialDates"
              :label="dateRange.id"
              :key="dateRange.id"
              :disabled="selectedWeekday == dateRange.id"
            >
              {{ dateRange.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="copyDateDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleCopyDate">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import commonApi from '@/api'
import API from '../../service/api'
import { ref, reactive, computed, onMounted, watch } from 'vue'
import PackageTypeDialog from './components/PackageTypeDialog.vue'
import PackageListDialog from './components/PackageListDialog.vue'
import AddTimeSlotDialog from './components/AddTimeSlotDialog.vue'
import { Delete, KnifeFork, Plus } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  name: 'StoreTimeManagement',
  components: {
    PackageTypeDialog,
    PackageListDialog,
    AddTimeSlotDialog,
    Delete,
    Plus,
  },
  setup() {
    // 搜索表单
    const searchForm = reactive({
      storeCode: '',
    })

    const selectedWeekday = ref('1')
    // 门店列表
    const storeList = ref([])
    // 周期数据
    const weekDays = ref([
      { label: '周一', id: '1' },
      { label: '周二', id: '2' },
      { label: '周三', id: '3' },
      { label: '周四', id: '4' },
      { label: '周五', id: '5' },
      { label: '周六', id: '6' },
      { label: '周日', id: '7' },
    ])
    // 添加当前选中日期的响应式变量
    const currentSelectedDate = ref('')
    // 特殊日期范围数据
    const specialDates = ref([])
    // 添加 watch 来更新 currentSelectedDate
    watch(
      selectedWeekday,
      newValue => {
        if (newValue <= 7) {
          console.log(weekDays)

          // 如果是周期
          const weekday = weekDays?.value?.find(day => day.id == newValue)
          currentSelectedDate.value = weekday ? weekday.label : ''
        } else {
          // 如果是特殊日期
          const specialDate = specialDates?.value?.find(date => date.id == newValue)
          currentSelectedDate.value = specialDate ? specialDate.label : ''
        }
      },
      { immediate: true },
    )

    onMounted(async () => {
      //获取门店数据
      const res = await commonApi.getNewShop({})
      storeList.value = res.data
        .map(item => ({
          value: item.code,
          label: `${item.code}--${item.name}`,
        }))
        .sort((a, b) => Number(a.value.slice(1)) - Number(b.value.slice(1)))
    })

    const handleStoreChange = async () => {
      selectedWeekday.value = 1
      //获取日期列表
      await getDateList()
      await getTimeSlotList()
    }

    watch(selectedWeekday, () => {
      //获取时段列表
      getTimeSlotList()
    })

    //获取日期列表
    const getDateList = async () => {
      const res = await API.getDateList({
        storeCode: searchForm.storeCode,
      })
      if (res.code == 200) {
        specialDates.value = res.data.specialDates.map((a, index) => ({
          label: a.specialDate,
          id: 8 + index,
          isHaveTimeSlot: a.isHaveTimeSlot,
        }))
        weekDays.value = weekDays.value.map(day => ({
          ...day,
          isHaveTimeSlot: res.data.dayOfWeeks.find(a => a.dayOfWeek == day.id).isHaveTimeSlot,
        }))
        console.log(specialDates.value)
      } else {
        ElMessage.error(res.msg)
      }
    }
    //获取时段列表
    const getTimeSlotList = async () => {
      let params = {
        storeCode: searchForm.storeCode,
      }
      if (selectedWeekday.value <= 7) {
        params.dayType = 1
        params.dayOfWeek = selectedWeekday.value
      } else {
        params.dayType = 2
        params.specialStartDate = specialDates.value
          .find(range => range.id === selectedWeekday.value)
          .label.split('-')[0]
        params.specialEndDate = specialDates.value.find(range => range.id === selectedWeekday.value).label.split('-')[1]
      }
      const res = await API.getTimeSlotList(params)
      if (res.code == 200) {
        timeTableData.value = res.data.map(item => {
          return {
            ...item,
            channel: item.channel?.split(','),
          }
        })
      } else {
        ElMessage.error(res.msg)
      }
    }

    //渠道
    const channels = ref({
      0: '小程序',
      1: 'POS',
      2: '抖音',
    })

    // 计算是否有选择日期
    const hasSelectedDates = computed(() => {
      return specialDates.value.some(range => range.dates && range.dates.length === 2)
    })

    // 禁用日期
    const disabledDate = time => {
      return time.getTime() < Date.now() - 8.64e7 // 禁用今天之前的日期
    }

    // 时段表格数据
    const timeTableData = ref([])

    // 包厢类型弹窗控制
    const packageTypeVisible = ref(false)

    // 包厢列表弹窗控制
    const packageListVisible = ref(false)

    // 添加时段弹窗控制
    const timeSlotDialogVisible = ref(false)

    // 特殊日期对话框控制
    const specialDateDialogVisible = ref(false)
    const specialDateRange = ref(null)

    // 复制日期对话框控制
    const copyDateDialogVisible = ref(false)
    const copyForm = reactive({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      specialDates: [],
    })

    // 添加计算属性来过滤周期选项
    const filteredWeekdays = computed(() => {
      const weekdayMap = {
        monday: '周一',
        tuesday: '周二',
        wednesday: '周三',
        thursday: '周四',
        friday: '周五',
        saturday: '周六',
        sunday: '周日',
      }

      // 如果当前选中的是周期，则从复制选项中移除该周期
      const selectedWeekday = weekDays.value.find(day => day.selected)
      if (selectedWeekday) {
        return Object.entries(weekdayMap).filter(([key, value]) => value !== selectedWeekday.label)
      }
      return Object.entries(weekdayMap)
    })

    // 添加计算属性来过滤特殊日期
    const filteredSpecialDates = computed(() => {
      // 如果当前选中的是特殊日期，则从复制选项中移除该日期范围
      return [...weekDays.value, ...specialDates.value]
    })

    // 方法
    const handlePackageType = () => {
      packageTypeVisible.value = true
    }

    const handlePackageList = () => {
      packageListVisible.value = true
    }

    const goToStoreFront = () => {
      // 跳转到开店宝
      window.open(
        'https://e.dianping.com/app/merchant-platform/e241517539f4485?iUrl=Ly9lLmRpYW5waW5nLmNvbS9zYy9wYy9tZXJjaGFudC9ib29rLW1lYWwtcGFnZQ',
        '_blank',
      )
      return false
    }

    const addTimeSlot = () => {
      timeSlotDialogVisible.value = true
    }

    const selectWeekDay = index => {
      // 先清除所有特殊日期的选中状态
      specialDates.value.forEach(range => {
        range.selected = false
      })
      // 清除其他周的选中状态
      weekDays.value.forEach((day, i) => {
        if (i !== index) {
          day.selected = false
        }
      })
      // 切换当前周的选中状态
      weekDays.value[index].selected = !weekDays.value[index].selected

      //获取时段
      getTimeSlotList()
    }

    // 显示添加特殊日期对话框
    const showAddSpecialDate = () => {
      // if (specialDates.value.length >= 2) {
      //   ElMessage.warning('最多只能添加两个特殊日期范围')
      //   return
      // }
      specialDateRange.value = null
      specialDateDialogVisible.value = true
    }

    // 处理添加特殊日期
    const handleAddSpecialDate = async () => {
      if (!specialDateRange.value) {
        ElMessage.warning('请选择日期范围')
        return
      }

      //添加特殊日期
      const res = await API.addSpecialDate({
        storeCode: searchForm.storeCode,
        specialDate: specialDateRange.value.map(a => a.replace(/-/g, '.')).join('-'),
      })
      if (res.code == 200) {
        getDateList()
        specialDateDialogVisible.value = false
        ElMessage.success('添加特殊日期成功')
      } else {
        ElMessage.error(res.msg)
      }
    }

    const copyOtherDate = () => {
      if (searchForm.storeCode == '') {
        ElMessage.warning('请先选择门店')
        return
      }

      // 重置表单
      copyForm.specialDates = []

      copyDateDialogVisible.value = true
    }

    // 处理复制日期
    const handleCopyDate = async () => {
      const hasSelected = Object.values(copyForm).some(value => (Array.isArray(value) ? value.some(v => v) : value))

      if (!hasSelected) {
        ElMessage.warning('请选择要复制到的日期')
        return
      }

      // 这里实现复制逻辑
      let params = {
        storeCode: searchForm.storeCode,
        //目标日期
        toDays: copyForm.specialDates.map(a => {
          if (a <= 7) {
            return a
          } else {
            return specialDates.value.find(range => range.id === a).label
          }
        }),
        fromDay:
          selectedWeekday.value <= 7
            ? selectedWeekday.value
            : specialDates.value.find(range => range.id === selectedWeekday.value).label,
      }

      let res = await API.copyTimeSlot(params)
      if (res.code == 200) {
        ElMessage.success('复制成功')
        getDateList()
        getTimeSlotList()
      } else {
        ElMessage.error(res.msg)
      }
      copyDateDialogVisible.value = false
    }

    const deleteTimeSlot = async row => {
      ElMessageBox.confirm('确定要删除当前时段吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          // 删除时段
          let res = await API.deleteTimeSlot({
            code: row.code,
          })
          if (res.code == 200) {
            ElMessage.success('删除成功')
            getDateList()
            getTimeSlotList()
          } else {
            ElMessage.error(res.msg)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    // 处理添加时段成功
    const handleTimeSlotSuccess = async timeSlot => {
      let params = {
        storeCode: searchForm.storeCode,
        channel: timeSlot.channels.join(','),
        startTime: timeSlot.startTime,
        endTime: timeSlot.endTime,
      }

      if (selectedWeekday.value <= 7) {
        params.dayType = 1
        params.dayOfWeek = selectedWeekday.value
      } else {
        params.dayType = 2
        ;(params.specialStartDate = specialDates.value
          .find(range => range.id === selectedWeekday.value)
          .label.split('-')[0]),
          (params.specialEndDate = specialDates.value
            .find(range => range.id === selectedWeekday.value)
            .label.split('-')[1])
      }
      let res = await API.addTimeSlot(params)
      if (res.code == 200) {
        ElMessage.success('添加时段成功')
        getTimeSlotList()
        getDateList()
      } else {
        ElMessage.error(res.msg)
      }
    }

    // 在 script 的 setup 中添加删除相关方法
    const confirmDelete = (dateRange, index) => {
      ElMessageBox.confirm('确定要删除该特殊日期吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          // 删除时段
          const res = await API.deleteSpecialDate({
            storeCode: searchForm.storeCode,
            specialDate: dateRange.label,
          })
          if (res.code == 200) {
            specialDates.value.splice(index, 1)
            if (selectedWeekday.value == dateRange.id) {
              selectedWeekday.value = 1
            }
            ElMessage.success('删除成功')
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    return {
      channels,
      searchForm,
      storeList,
      weekDays,
      specialDates,
      timeTableData,
      handlePackageType,
      handlePackageList,
      goToStoreFront,
      addTimeSlot,
      selectWeekDay,
      showAddSpecialDate,
      copyOtherDate,
      deleteTimeSlot,
      packageTypeVisible,
      packageListVisible,
      timeSlotDialogVisible,
      handleTimeSlotSuccess,
      hasSelectedDates,
      specialDateDialogVisible,
      specialDateRange,
      handleAddSpecialDate,
      confirmDelete,
      copyDateDialogVisible,
      copyForm,
      handleCopyDate,
      handleStoreChange,
      filteredWeekdays,
      filteredSpecialDates,
      currentSelectedDate,
      selectedWeekday,
      disabledDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.store-time-management {
  padding: 20px;

  .search-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .search-item {
      margin-right: 20px;

      &.right {
        margin-left: auto;
      }

      .label {
        margin-right: 10px;
      }
    }
  }

  .date-section {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;

    .date-actions {
      margin-bottom: 20px;
    }

    .time-selection {
      margin-bottom: 20px;

      .selection-group {
        margin-bottom: 16px;

        .selection-title {
          font-size: 14px;
          color: #606266;
          margin-bottom: 12px;
        }

        .selection-content {
          :deep(.el-radio-group) {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .date-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              margin-left: 20px;

              .el-radio-button__inner {
                border-radius: 4px;
              }

              .status-dot {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin-top: 4px;
                background-color: #dcdfe6;
                transition: background-color 0.3s;

                &.has-time {
                  background-color: #409eff;
                }
              }

              .el-icon-delete {
                position: absolute;
                right: -16px;
                top: 50%;
                transform: translateY(-50%);
                color: #f56c6c;

                &:hover {
                  color: #f78989;
                }
              }
            }
          }
        }
      }
    }

    .special-date-actions {
      display: flex;
      gap: 10px;
    }
  }

  .time-management-area {
    .add-time {
      margin-bottom: 20px;

      .tip-text {
        margin-top: 10px;
        color: #666;
        font-size: 14px;
      }
    }

    .week-selection {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .week-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 15px;
        padding: 10px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        cursor: pointer;

        &.active {
          border-color: #409eff;
          color: #409eff;
        }

        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-top: 5px;
          background-color: #dcdfe6;

          &.has-time {
            background-color: #409eff;
          }
        }
      }
    }

    .special-date {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .date-range-group {
        display: flex;
        align-items: center;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        .date-display {
          padding: 10px;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          background-color: #fff;
          cursor: pointer;

          &.active {
            border-color: #409eff;
            color: #409eff;
          }

          .separator {
            margin: 0 8px;
            color: inherit;
          }

          .date-text {
            font-weight: normal;
            color: inherit;
          }

          .dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-top: 5px;
            background-color: #dcdfe6;

            &.has-time {
              background-color: #409eff;
            }
          }
        }

        .delete-icon {
          margin-left: 8px;
          color: #f56c6c;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .date-operations {
      margin: 20px 0;
      display: flex;
      gap: 10px;
      justify-content: flex-end;

      .el-button {
        padding: 9px 15px;
        font-size: 14px;
      }
    }

    .time-table {
      .channel-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;

        .channel-item {
          padding: 2px 8px;
          background-color: #f5f7fa;
          border-radius: 4px;
          font-size: 12px;
        }
      }
    }
  }

  .special-date-form {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .copy-date-form {
    padding: 0 20px;

    .warning-tip {
      color: #f56c6c;
      font-size: 14px;
      margin-bottom: 15px;
      line-height: 1.5;
    }

    .date-tip {
      color: #606266;
      font-size: 14px;
      margin-bottom: 15px;
    }

    .copy-to {
      color: #606266;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .weekday-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      margin-bottom: 15px;
    }

    .special-date-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
</style>
